import { Autocomplete, DirectionsRenderer, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, {useState, useRef} from 'react';
import { ReactDOM } from 'react';

import Alert from 'react-bootstrap/Alert';

var message = "NULL";



function GetDistancefromCurrentLocation() {

    const key = 'AIzaSyB-IhLNuNEMTKd8VHG8Xyij2srPNVS_PR4';


    // const {isLoaded} = useJsApiLoader({
    //    googleMapsApiKey: key ,
    //    libraries:['places'],
    // })


//    if(!isLoaded){
//       return <div>Error</div>
//    }

 const service = new window.google.maps.DistanceMatrixService();

 const destinationB = { lat: 17.626331, lng: 78.944286 };

if (navigator.geolocation) {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      const currentPos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

       const request = {
          origins: [currentPos],
          destinations: [destinationB],
          travelMode: window.google.maps.TravelMode.DRIVING,
          unitSystem: window.google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
        };


        service.getDistanceMatrix(request).then((response) => {
          // put response

          const originList = response.originAddresses;
          const destinationList = response.destinationAddresses;


          const distance  = "Distance from your location to the site is " + response.rows[0].elements[0].distance.text;
          const duration  = ". It takes about  " + response.rows[0].elements[0].duration.text + " to reach there";
          //locationButton.textContent = distance + duration; //commented
          //alert(distance+duration)
           message = distance+duration;

          alert(message);

        //    <Alert variant="success">
        //   message
        // </Alert>


      });
    },
    () => {

    }
  );
}
else {
      // Browser doesn't support Geolocation
      handleLocationError(false);
    }




}

function handleLocationError(browserHasGeolocation, infoWindow, pos) {
  infoWindow.setPosition(pos);
  infoWindow.setContent(
    browserHasGeolocation ?
    "Error: The Geolocation service failed." :
    "Error: Your browser doesn't support geolocation."
  );
}

export default function Testmap() {

return (

    <>


    {GetDistancefromCurrentLocation()}








    </>



);

}
