/*global google*/


import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
//import "./style.css"

//const google = window.google;



function GoogleMaps() {

    //lat: 17.644960, lng: 79.090119

      const apiIsLoaded = (map, maps) => {

        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();
        const destinationB = { lat: 17.542564, lng: 78.865544 };
        directionsRenderer.setMap(map);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const currentPos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                };

                const request = {
                  origin: currentPos,
                  destination: destinationB,
                  travelMode: google.maps.TravelMode.DRIVING,
                  unitSystem: google.maps.UnitSystem.METRIC,
                  avoidHighways: false,
                  avoidTolls: false,
                };
                directionsService.route(request, function(response, status) {
                  if (status == google.maps.DirectionsStatus.OK) {
                    console.log(response);
                    directionsRenderer.setDirections(response);

                  }
                });


              },
              () => {


              }
            );
          }

          //FROM HERE


          const infoWindow = new maps.InfoWindow();
          const rvhgreencity = [

            {lat: 17.540861, lng: 78.866161 },
             {lat: 17.541788, lng:78.866373 },
            {lat: 17.541886, lng:78.866010},
            {lat: 17.542234, lng:78.864676},
            {lat: 17.542758, lng: 78.864616},
            {lat: 17.543047, lng:78.864645},
            {lat: 17.543280, lng:78.864715},
            {lat: 17.543325, lng: 78.866777},
            {lat: 17.542692, lng: 78.866586},
            {lat: 17.542475, lng:78.866940},
            {lat: 17.540762, lng:78.866441},

          ];

          const rvhgreencitylayout = new maps.Polygon({
            paths: rvhgreencity,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "WHITE",
            fillOpacity: 0.4,
          });

          rvhgreencitylayout.setMap(map);

          const image =
              "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";



           const mainFlag1 = new maps.Marker({
              position:  {lat: 17.537599, lng: 78.860587 },
               map,
              icon: image,
            });

            const mainFlag2 = new maps.Marker({
              position: {lat: 17.538545, lng:78.861278},
              map,
              icon: image,
            });

           const mainFlag3 = new maps.Marker({
              position: {lat: 17.539289, lng: 78.861827},
              map,
              icon: image,
            });

           const mainFlag4 = new maps.Marker({
              position: {lat: 17.539931, lng: 78.862962},
              map,
              icon: image,
            });

          const mainFlag5 = new maps.Marker({
              position: {lat: 17.540320, lng: 78.864486},
              map,
              icon: image,
            });

          //flags

          const mainFlag7 = new maps.Marker({
              position: {lat: 17.540505, lng:78.865585},
              map,
              icon: image,
            });

          const mainFlag8 = new maps.Marker({
              position: {lat: 17.540742, lng:78.866263},
              map,
              icon: image,
            });

          const mainFlag9 = new maps.Marker({
              position: {lat: 17.541484, lng:78.865850},
              map,
              icon: image,
            });


          const mainFlag10 = new maps.Marker({
              position: {lat: 17.542138, lng:78.864478},
              map,
              icon: image,
          });

          const mainFlag11 = new maps.Marker({
            position: {lat: 17.542736, lng:78.864394},
           map,
            icon: image,
          });

          const mainFlag12 = new maps.Marker({
            position: {lat: 17.543319, lng:78.864545},
           map,
            icon: image,
          });

          const mainFlag13 = new maps.Marker({
            position: {lat: 17.543446, lng:78.865498},
           map,
            icon: image,
          });
          const mainFlag14 = new maps.Marker({
            position: {lat: 17.543414, lng:78.866393},
           map,
            icon: image,
          });

          const mainFlag15 = new maps.Marker({
            position: {lat: 17.543334, lng:78.866862},
           map,
            icon: image,
          });

          const mainFlag16 = new maps.Marker({
            position: {lat: 17.542752, lng:78.866703},
           map,
            icon: image,
          });

          const mainFlag17 = new maps.Marker({
            position: {lat: 17.542481, lng:78.867104},
           map,
            icon: image,
          });

          const mainFlag18 = new maps.Marker({
            position: {lat: 17.541899, lng:78.866920},
           map,
            icon: image,
          });

          const mainFlag19 = new maps.Marker({
            position: {lat: 17.541409, lng:78.866783},
           map,
            icon: image,
          });


          const mainrdc = [

            {lat: 17.542010, lng: 78.866802},
            {lat: 17.541861, lng:78.864680},
            {lat: 17.541672, lng: 78.864571},
            {lat: 17.541760, lng: 78.866724},


            ]

            const mainrd = new maps.Polygon({
              paths: mainrdc,
              strokeColor: "RED",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "BLACK",
              fillOpacity: 0.5,
            });

            mainrd.setMap(map);

            const htline = new maps.Marker({
              position: { lat: 17.541792, lng:78.864858},



              label: {text: "HT Line", color: "White", fontSize:"10px"},
              icon: {
                path: maps.SymbolPath.CIRCLE,
                scale: 10,
                strokeWeight: 0,
                fillColor: 'WHITE',
                strokeColor: 'WHITE',
                fillOpacity: 0
              },
              map: map,
             title: "40ft wide road",
              optimized: false,
            });

            const main40ft = new maps.Marker({
              position: { lat: 17.541891, lng:78.865575},
              label: {text: "Proposed 33ft road", color: "White", fontSize:"10px"},
              icon: {
                path: maps.SymbolPath.CIRCLE,
                scale: 10,
                strokeWeight: 0,
                fillColor: 'WHITE',
                strokeColor: 'WHITE',
                fillOpacity: 0
              },
              map: map,
             title: "40ft wide road",
              optimized: false,
            });


            main40ft.addListener("click", () => {

                infoWindow.close();
                infoWindow.setContent(main40ft.getTitle());
                infoWindow.open(main40ft.getMap(), main40ft);
            });

            const maincrrdc = [

              {lat: 17.542441, lng: 78.866923},



              {lat: 17.542419, lng: 78.864650},



              {lat: 17.542343, lng: 78.864666},



              {lat: 17.542372, lng: 78.866908},




              ]

              const maincrrd = new maps.Polygon({
                paths: maincrrdc,
                strokeColor: "BLACK",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "BLACK",
                fillOpacity: 0.8,
              });

              maincrrd.setMap(map);

              const main130ft = new maps.Marker({
                position: { lat: 17.542399, lng:78.865839},
                 label: {text: "30ft wide road", color: "white", fontSize:"8px"},
                icon: {
                  path: maps.SymbolPath.CIRCLE,
                  scale: 10,
                  strokeWeight: 0,
                  fillColor: 'WHITE',
                  strokeColor: 'WHITE',
                  fillOpacity: 0
                },
                map: map,
               title: "Proposed 18m Master Plan road",
                optimized: false,
              });



              main130ft.addListener("click", () => {

                  infoWindow.close();
                  infoWindow.setContent(main130ft.getTitle());
                  infoWindow.open(main130ft.getMap(), main130ft);
              });

          const maincrrdc2 = [

          {lat:17.541774, lng: 78.866467},
          {lat: 17.540839, lng: 78.866266},
          {lat: 17.540810, lng: 78.866343},
          {lat: 17.541760, lng: 78.866576},

          ]

          const maincrrd2 = new maps.Polygon({
            paths: maincrrdc2,
            strokeColor: "BLACK",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "BLACK",
            fillOpacity: 0.8,
          });

          maincrrd2.setMap(map);

          const main230ft = new maps.Marker({
            position: { lat: 17.541409, lng:78.866443},
            label: {text: "30ft", color: "red", fontSize:"8px"},
            icon: {
              path: maps.SymbolPath.CIRCLE,
              scale: 10,
              strokeWeight: 1,
              fillColor: 'WHITE',
              strokeColor: 'WHITE',
              fillOpacity: 1
            },
            map: map,
           title: "30ft wide road",
            optimized: false,
          });



          main230ft.addListener("click", () => {

              infoWindow.close();
              infoWindow.setContent(main230ft.getTitle());
              infoWindow.open(main230ft.getMap(), main230ft);
          });





          const maincrrdc3 = [
            {lat: 17.541160, lng: 78.865569},
            {lat: 17.540540, lng: 78.866849},
            {lat: 17.540348, lng: 78.866686},
            {lat: 17.540968, lng: 78.865590},

            ]

            const maincrrd3 = new maps.Polygon({
              paths: maincrrdc3,
              strokeColor: "BLACK",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "BLACK",
              fillOpacity: 0.8,
            });

            maincrrd3.setMap(map);

            const main330ft = new maps.Marker({
              position: { lat: 17.540828, lng:78.865979},
              label: {text: "Existing 30ft road", color: "white", fontSize:"8px"},
              icon: {
                path: maps.SymbolPath.CIRCLE,
                scale: 10,
                strokeWeight: 0,
                fillColor: 'WHITE',
                strokeColor: 'WHITE',
                fillOpacity: 0
              },
              map: map,
             title: "30ft wide road",
              optimized: false,
            });



            main330ft.addListener("click", () => {

                infoWindow.close();
                infoWindow.setContent(main330ft.getTitle());
                infoWindow.open(main330ft.getMap(), main330ft);
            });

            const maincrrdc4 = [

              {lat: 17.543289, lng: 78.866401},
              {lat: 17.541988, lng: 78.866431},
              {lat: 17.541991, lng: 78.866508},
              {lat: 17.543289, lng: 78.866486},


              ]

              const maincrrd4 = new maps.Polygon({
                paths: maincrrdc4,
                strokeColor: "BLACK",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "BLACK",
                fillOpacity: 0.8,
              });

              maincrrd4.setMap(map);

          const main430ft = new maps.Marker({
            position: { lat: 17.542249, lng:78.866451},
            label: {text: "30ft", color: "red", fontSize:"8px"},
            icon: {
              path: maps.SymbolPath.CIRCLE,
              scale: 10,
              strokeWeight: 1,
              fillColor: 'WHITE',
              strokeColor: 'WHITE',
              fillOpacity: 1
            },
            map: map,
            title: "30ft wide road",
            optimized: false,
          });



          main430ft.addListener("click", () => {

              infoWindow.close();
              infoWindow.setContent(main430ft.getTitle());
              infoWindow.open(main430ft.getMap(), main430ft);
          });




          const subrd1c = [

            {lat: 17.543288, lng:78.865627 },

            {lat: 17.541983, lng: 78.865639},

            {lat: 17.541957, lng: 78.865711},

            {lat: 17.543284, lng: 78.865711},

            ]

            const subrd1 = new maps.Polygon({
              paths: subrd1c,
              strokeColor: "BLACK",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "BLACK",
              fillOpacity: 0.8,
            });

            subrd1.setMap(map);

          const main530ft = new maps.Marker({
            position: { lat: 17.542776, lng:78.865669},
            label: {text: "30ft", color: "red", fontSize:"8px"},
            icon: {
              path: maps.SymbolPath.CIRCLE,
              scale: 10,
              strokeWeight: 1,
              fillColor: 'WHITE',
              strokeColor: 'WHITE',
              fillOpacity: 1
            },
            map: map,
            title: "30ft wide road",
            optimized: false,
          });



          main530ft.addListener("click", () => {

              infoWindow.close();
              infoWindow.setContent(main530ft.getTitle());
              infoWindow.open(main530ft.getMap(), main530ft);
          });



          const subrd11c = [

          {lat:  17.543282, lng:78.864835},
          {lat: 17.542425, lng: 78.864851},
          {lat: 17.542432, lng:78.864956},
          {lat: 17.543284, lng: 78.864938},

          ]

          const subrd11 = new maps.Polygon({
            paths: subrd11c,
            strokeColor: "BLACK",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "BLACK",
            fillOpacity: 0.8,
          });

          subrd11.setMap(map);

          const main630ft = new maps.Marker({
            position: { lat: 17.542887, lng:78.864882},
            label: {text: "30ft", color: "red", fontSize:"8px"},
            icon: {
              path: maps.SymbolPath.CIRCLE,
              scale: 10,
              strokeWeight: 1,
              fillColor: 'WHITE',
              strokeColor: 'WHITE',
              fillOpacity: 1
            },
            map: map,
            title: "30ft wide road",
            optimized: false,
          });

          main630ft.addListener("click", () => {

              infoWindow.close();
              infoWindow.setContent(main630ft.getTitle());
              infoWindow.open(main630ft.getMap(), main630ft);
          });


          const subrd12c = [

            {lat:  17.543287, lng:78.866003},
            {lat: 17.541918, lng: 78.866010},
            {lat: 17.541921, lng:78.866108},
            {lat: 17.543291, lng: 78.866091},

            ]

            const subrd12 = new maps.Polygon({
              paths: subrd12c,
              strokeColor: "BLACK",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "BLACK",
              fillOpacity: 0.8,
            });

            subrd12.setMap(map);

            const subrd12c30ft = new maps.Marker({
              position: { lat: 17.542807, lng:78.866059},
              label: {text: "30ft", color: "red", fontSize:"8px"},
              icon: {
                path: maps.SymbolPath.CIRCLE,
                scale: 10,
                strokeWeight: 1,
                fillColor: 'WHITE',
                strokeColor: 'WHITE',
                fillOpacity: 1
              },
              map: map,
              title: "30ft wide road",
              optimized: false,
            });

            subrd12c30ft.addListener("click", () => {

                infoWindow.close();
                infoWindow.setContent(subrd12c30ft.getTitle());
                infoWindow.open(subrd12c30ft.getMap(), subrd12c30ft);
            });

            const subrd13c = [

              {lat:  17.543294, lng:78.865224},
              {lat: 17.542433, lng: 78.865237},
              {lat: 17.542439, lng:78.865336},
              {lat: 17.543294, lng: 78.865306},

              ]

              const subrd13 = new maps.Polygon({
                paths: subrd13c,
                strokeColor: "BLACK",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "BLACK",
                fillOpacity: 0.8,
              });

              subrd13.setMap(map);

              const subrd13c30ft = new maps.Marker({
                position: { lat: 17.542848, lng:78.865267},
                label: {text: "30ft", color: "red", fontSize:"8px"},
                icon: {
                  path: maps.SymbolPath.CIRCLE,
                  scale: 10,
                  strokeWeight: 1,
                  fillColor: 'WHITE',
                  strokeColor: 'WHITE',
                  fillOpacity: 1
                },
                map: map,
                title: "30ft wide road",
                optimized: false,
              });

              subrd13c30ft.addListener("click", () => {

                  infoWindow.close();
                  infoWindow.setContent(subrd13c30ft.getTitle());
                  infoWindow.open(subrd13c30ft.getMap(), subrd13c30ft);
              });

          const park1c = [
            {lat: 17.542346, lng: 78.864664},
            {lat: 17.542227, lng: 78.864688},
            {lat: 17.542135, lng:78.865104},
            {lat: 17.542343, lng: 78.865102},


            ]


          const park1 = new maps.Polygon({
            paths: park1c,
            strokeColor: "GREEN",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "GREEN",
            fillOpacity: 0.5,
          });

          park1.setMap(map);

          const markPark1 = new maps.Marker({
            position: { lat: 17.542224, lng:78.864991 },
            label: {text: "Park Area", color: "white", fontSize: "8px"},
            icon: {
              path: maps.SymbolPath.CIRCLE,
              scale: 8,
              strokeWeight: 0,
              fillColor: 'RED',
              strokeColor: 'RED',
              fillOpacity: 0
            },
            map: map,
              title: "SocialInfra"
          });



          const park2c = [
            {lat: 17.542346, lng: 78.864664},
            {lat:  17.542274, lng: 78.864683},
            {lat: 17.542276, lng:78.864881},
            {lat: 17.542348, lng:78.864877},


            ]


            const park2 = new maps.Polygon({
              paths: park2c,
              strokeColor: "GREEN",
              strokeOpacity: 0.8,
              strokeWeight: 1,
              fillColor: "BROWN",
              fillOpacity: 0.8,
            });

            park2.setMap(map);

            const markPark2 = new maps.Marker({
              position: { lat: 17.542320, lng:78.864760},

              label: {text: "Uitlity", color: "white", fontSize: "8px"},
              icon: {
                path: maps.SymbolPath.CIRCLE,
                scale: 8,
                strokeWeight: 0,
                fillColor: 'RED',
                strokeColor: 'RED',
                fillOpacity: 0
              },
              map: map,
                title: "Park Area"
            });


          const park3c = [
            {lat: 17.542135, lng:78.865104},
            {lat: 17.542343, lng: 78.865102},
          {lat: 17.542344, lng: 78.865634},
          {lat: 17.541984, lng: 78.865638},

          ]


          const park3 = new maps.Polygon({
            paths: park3c,
            strokeColor: "GREEN",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "BLUE",
            fillOpacity: 0.5,
          });

          park3.setMap(map);

          const markPark3 = new maps.Marker({
            position: { lat: 17.542244, lng:78.865447 },
            label: {text: "SocialInfra", color: "white", fontSize: "8px"},
            icon: {
              path: maps.SymbolPath.CIRCLE,
              scale: 8,
              strokeWeight: 0,
              fillColor: 'RED',
              strokeColor: 'RED',
              fillOpacity: 0
            },
            map: map,
              title: "Open Space"
          });

          const park4c = [
          {lat: 17.543278, lng:78.866522},
          {lat: 17.542465, lng: 78.866522},
          {lat: 17.542469, lng: 78.866704},
          {lat: 17.542574, lng: 78.866732},
          {lat: 17.542685, lng: 78.866570},
          {lat: 17.543281, lng: 78.866743},

          ]


          const park4 = new maps.Polygon({
            paths: park4c,
            strokeColor: "GREEN",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "GREEN",
            fillOpacity: 0.8,
          });

          park4.setMap(map);

          const markPark4 = new maps.Marker({
            position: { lat: 17.543233, lng:78.866614 },
            label: {text: "Park Area", color: "white", fontSize: "8px"},
            icon: {
              path: maps.SymbolPath.CIRCLE,
              scale: 8,
              strokeWeight: 0,
              fillColor: 'RED',
              strokeColor: 'RED',
              fillOpacity: 0
            },
            map: map,
              title: "Open Space"
          });


          const park5c = [
            {lat:  17.542446, lng:78.866727},
            {lat: 17.542574, lng: 78.866766},
            {lat: 17.542465, lng: 78.866937},
            {lat: 17.542437, lng: 78.866923},
            {lat: 17.542437, lng: 78.866923},

          ]


          const park5 = new maps.Polygon({
            paths: park5c,
            strokeColor: "BROWN",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "BROWN",
            fillOpacity: 0.8,
          });

          park5.setMap(map);

          const markPark5 = new maps.Marker({
            position: { lat: 17.542470, lng:78.866829 },
            label: {text: "Utility", color: "white", fontSize: "8px"},
            icon: {
              path: maps.SymbolPath.CIRCLE,
              scale: 8,
              strokeWeight: 0,
              fillColor: 'RED',
              strokeColor: 'RED',
              fillOpacity: 0
            },
            map: map,
              title: "Open Space"
          });



        };



      return (
        <div>
          <div style={{ height: "100vh", width: "100%",  position: "inherit", overflow:"hidden", margin: "0 auto" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyB-IhLNuNEMTKd8VHG8Xyij2srPNVS_PR4"
              }}

              defaultCenter={{ lat: 17.542564, lng: 78.865544 }}
              defaultZoom={10}
            //   center={this.state.currentLocation}
              center={{ lat: 17.542564, lng: 78.865544 }}
              yesIWantToUseGoogleMapApiInternals
              //onGoogleApiLoaded={({ map, maps }) => renderPolygon(map, maps)}
              onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
              options={{
                styles: [ { featureType: "all", elementType: "labels", stylers: [ { visibility:  "on", } ] } ],
                mapTypeControl: false,
                mapTypeId: "hybrid",
                gestureHandling: "greedy",
                mapTypeControl: false,

                streetViewControl: false,

                zoomControl: false,

                fullscreenControl: true,

                rotateControl: true,

                disableDefaultUI: false,

                panControl: true,

                scaleControl: true,

                streetViewControl: true,

                overviewMapControl: true,
              }}
            />
          </div>
        </div>
      );
    //} //uncomment
  }

  export default GoogleMaps;
