import React, { useRef, useState, useEffect, Fragment } from "react";
import GoogleMapReact from "google-map-react";
import firebase from "./firebase";
import { BrowserRouter as Router } from 'react-router-dom';

function Maptwo() {

  const mapRef = useRef(null);
  const [mapData, setMapData] = useState(null);


  useEffect(() => {
    const fetchMapData = async () => {
      try {
        const proxyGoogleMaps = await firebase
          .functions()
          .httpsCallable("proxyGoogleMaps");

        const { data } = await proxyGoogleMaps({
          url: "http://maps.googleapis.com/maps/api/js?libraries=geometry&sensor=false&",
        });
        setMapData(data);
      } catch (error) {
        console.error("Error fetching map data:", error);
      }
    };
    fetchMapData();
  }, []);

  useEffect(() => {
    if (setMapData) {
      const script = document.createElement("script");

      script.type = "text/javascript";
      script.innerHTML = mapData;
      console.log("script ", script);
      document.head.appendChild(script);
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [mapData]);

  const defaultProps = {
    center: { lat: 17.626331, lng: 78.944286 },
    zoom: 18,
  };

    //from here
const renderPolygon = (map, maps) => {

  const infoWindow = new maps.InfoWindow();
  const rvhorigin = [

    {lat: 17.625769, lng: 78.941845 },
    {lat: 17.626611, lng:78.941932 },
    {lat: 17.626654, lng:78.944204},
    {lat: 17.626565, lng:78.944431},
    {lat: 17.626778, lng: 78.944561},
    {lat: 17.626534, lng:78.945012},
    {lat: 17.626666, lng:78.945088},
    {lat: 17.626755, lng: 78.945599},
    {lat: 17.626730, lng: 78.945682},
    {lat: 17.626158, lng:78.946643},
    {lat: 17.624314, lng:78.945625},
    {lat: 17.624465, lng:78.945390},
    {lat: 17.624822, lng:78.945273},
    {lat: 17.624883, lng:78.945194},
    {lat: 17.624890, lng:78.944877},
    {lat: 17.625191, lng:78.944835},
    {lat: 17.625620, lng:78.943908},

  ];

  const rvhoriginlayout = new maps.Polygon({
    paths: rvhorigin,
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "WHITE",
    fillOpacity: 0.4,
  });

  rvhoriginlayout.setMap(map);

  const image =
      "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";



   const mainFlag1 = new maps.Marker({
      position:  {lat: 17.626644, lng: 78.941860 },
      map,
      icon: image,
    });

    const mainFlag2 = new maps.Marker({
      position: {lat: 17.626744, lng:78.944240},
      map,
      icon: image,
    });

   const mainFlag3 = new maps.Marker({
      position: {lat: 17.626836, lng: 78.944936},
      map,
      icon: image,
    });

   const mainFlag4 = new maps.Marker({
      position: {lat: 17.626892, lng: 78.945717},
      map,
      icon: image,
    });

  const mainFlag5 = new maps.Marker({
      position: {lat: 17.626224, lng: 78.946762},
      map,
      icon: image,
    });

  //flags

  const mainFlag7 = new maps.Marker({
      position: {lat: 17.625011, lng:78.946200},
      map,
      icon: image,
    });

  const mainFlag8 = new maps.Marker({
      position: {lat: 17.624283, lng:78.947623},
      map,
      icon: image,
    });

  const mainFlag9 = new maps.Marker({
      position: {lat: 17.623098, lng:78.948710},
      map,
      icon: image,
    });


  const mainFlag10 = new maps.Marker({
      position: {lat: 17.622333, lng:78.950018},
      map,
      icon: image,
  });

  const mainFlag11 = new maps.Marker({
    position: {lat: 17.621692, lng:78.951351},
   map,
    icon: image,
  });

  const mainFlag12 = new maps.Marker({
    position: {lat: 17.624261, lng:78.945590},
   map,
    icon: image,
  });

  const mainFlag13 = new maps.Marker({
    position: {lat: 17.625051, lng:78.944688},
   map,
    icon: image,
  });
  // 17., 78.
  const mainFlag14 = new maps.Marker({
    position: {lat: 17.625507, lng:78.943857},
   map,
    icon: image,
  });

  const mainFlag15 = new maps.Marker({
    position: {lat: 17.625663, lng:78.941877},
   map,
    icon: image,
  });

  const mainFlag16 = new maps.Marker({
    position: {lat: 17.626299, lng:78.941692},
   map,
    icon: image,
  });

  const mainFlag17 = new maps.Marker({
    position: {lat: 17.624815, lng:78.946314},
   map,
    icon: image,
  });

  const mainFlag18 = new maps.Marker({
    position: {lat: 17.624658, lng:78.946917},
   map,
    icon: image,
  });

  const mainFlag19 = new maps.Marker({
    position: {lat: 17.623792, lng:78.947859},
   map,
    icon: image,
  });





  const mainrdc = [

    {lat: 17.626388, lng: 78.941926},
    {lat: 17.626141, lng:78.943927},
    {lat: 17.626127, lng: 78.944019},
    {lat: 17.625061, lng: 78.946022},
    {lat: 17.624974, lng: 78.945957},
    {lat: 17.626018, lng: 78.943981},
    {lat: 17.626298, lng: 78.941907},


    ]

    const mainrd = new maps.Polygon({
      paths: mainrdc,
      strokeColor: "BLACK",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "BLACK",
      fillOpacity: 0.8,
    });

    mainrd.setMap(map);

    const main40ft = new maps.Marker({
      position: { lat: 17.626178, lng:78.943158},
      label: {text: "40ft Wide Road", color: "White", fontSize:"8px"},
      icon: {
        path: maps.SymbolPath.CIRCLE,
        scale: 10,
        strokeWeight: 0,
        fillColor: 'WHITE',
        strokeColor: 'WHITE',
        fillOpacity: 0
      },
      map: map,
     title: "40ft wide road",
      optimized: false,
    });



    main40ft.addListener("click", () => {

        infoWindow.close();
        infoWindow.setContent(main40ft.getTitle());
        infoWindow.open(main40ft.getMap(), main40ft);
    });

    const maincrrdc = [

      {lat: 17.626613, lng: 78.942288},
       {lat: 17.626621, lng: 78.942472},
         {lat: 17.625722, lng: 78.942359},
       {lat: 17.625747, lng: 78.942171},


      ]

      const maincrrd = new maps.Polygon({
        paths: maincrrdc,
        strokeColor: "BLACK",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "BLACK",
        fillOpacity: 0.8,
      });

      maincrrd.setMap(map);

      const main130ft = new maps.Marker({
        position: { lat: 17.626001, lng:78.942303},
        label: {text: "18m", color: "red", fontSize:"8px"},
        icon: {
          path: maps.SymbolPath.CIRCLE,
          scale: 10,
          strokeWeight: 1,
          fillColor: 'WHITE',
          strokeColor: 'WHITE',
          fillOpacity: 1
        },
        map: map,
       title: "Proposed 18m Master Plan road",
        optimized: false,
      });



      main130ft.addListener("click", () => {

          infoWindow.close();
          infoWindow.setContent(main130ft.getTitle());
          infoWindow.open(main130ft.getMap(), main130ft);
      });

  const maincrrdc2 = [

  {lat:17.626709, lng: 78.944689},
  {lat: 17.625566, lng: 78.944025},
  {lat: 17.625534, lng: 78.944089},
  {lat: 17.626680, lng: 78.944761},
  ]

  const maincrrd2 = new maps.Polygon({
    paths: maincrrdc2,
    strokeColor: "BLACK",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "BLACK",
    fillOpacity: 0.8,
  });

  maincrrd2.setMap(map);

  const main230ft = new maps.Marker({
    position: { lat: 17.626272, lng:78.944474},
    label: {text: "30ft", color: "red", fontSize:"8px"},
    icon: {
      path: maps.SymbolPath.CIRCLE,
      scale: 10,
      strokeWeight: 1,
      fillColor: 'WHITE',
      strokeColor: 'WHITE',
      fillOpacity: 1
    },
    map: map,
   title: "30ft wide road",
    optimized: false,
  });



  main230ft.addListener("click", () => {

      infoWindow.close();
      infoWindow.setContent(main230ft.getTitle());
      infoWindow.open(main230ft.getMap(), main230ft);
  });





  const maincrrdc3 = [
    {lat: 17.627087, lng: 78.945303},
    {lat: 17.625417, lng: 78.944371},
    {lat: 17.625367, lng: 78.944461},
    {lat: 17.627039, lng: 78.945412},

    ]

    const maincrrd3 = new maps.Polygon({
      paths: maincrrdc3,
      strokeColor: "BLACK",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "BLACK",
      fillOpacity: 0.8,
    });

    maincrrd3.setMap(map);

    const main330ft = new maps.Marker({
      position: { lat: 17.625539, lng:78.944506},
      label: {text: "30ft", color: "red", fontSize:"8px"},
      icon: {
        path: maps.SymbolPath.CIRCLE,
        scale: 10,
        strokeWeight: 1,
        fillColor: 'WHITE',
        strokeColor: 'WHITE',
        fillOpacity: 1
      },
      map: map,
     title: "30ft wide road",
      optimized: false,
    });



    main330ft.addListener("click", () => {

        infoWindow.close();
        infoWindow.setContent(main330ft.getTitle());
        infoWindow.open(main330ft.getMap(), main330ft);
    });

    const maincrrdc4 = [

      {lat:17.624734, lng:78.944848},
      {lat: 17.624704, lng: 78.944903},
      {lat: 17.626561, lng: 78.945999},
      {lat: 17.626595, lng: 78.945935},


      ]

      const maincrrd4 = new maps.Polygon({
        paths: maincrrdc4,
        strokeColor: "BLACK",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "BLACK",
        fillOpacity: 0.8,
      });

      maincrrd4.setMap(map);

  const main430ft = new maps.Marker({
    position: { lat: 17.625881, lng:78.945565},
    label: {text: "30ft", color: "red", fontSize:"8px"},
    icon: {
      path: maps.SymbolPath.CIRCLE,
      scale: 10,
      strokeWeight: 1,
      fillColor: 'WHITE',
      strokeColor: 'WHITE',
      fillOpacity: 1
    },
    map: map,
    title: "30ft wide road",
    optimized: false,
  });



  main430ft.addListener("click", () => {

      infoWindow.close();
      infoWindow.setContent(main430ft.getTitle());
      infoWindow.open(main430ft.getMap(), main430ft);
  });




  const subrd1c = [

    {lat: 17.626358, lng:78.946372 },
    {lat: 17.624564, lng: 78.945322},
    {lat: 17.624418, lng: 78.945361},
    {lat: 17.626291, lng: 78.946456},

    ]

    const subrd1 = new maps.Polygon({
      paths: subrd1c,
      strokeColor: "BLACK",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "BLACK",
      fillOpacity: 0.8,
    });

    subrd1.setMap(map);

  const main530ft = new maps.Marker({
    position: { lat: 17.625780, lng:78.946102},
    label: {text: "30ft", color: "red", fontSize:"8px"},
    icon: {
      path: maps.SymbolPath.CIRCLE,
      scale: 10,
      strokeWeight: 1,
      fillColor: 'WHITE',
      strokeColor: 'WHITE',
      fillOpacity: 1
    },
    map: map,
    title: "30ft wide road",
    optimized: false,
  });



  main530ft.addListener("click", () => {

      infoWindow.close();
      infoWindow.setContent(main530ft.getTitle());
      infoWindow.open(main530ft.getMap(), main530ft);
  });



  const subrd11c = [

  {lat:  17.625696, lng:78.942905},
  {lat: 17.626640, lng: 78.943018},
  {lat: 17.626636, lng:78.943098},
  {lat: 17.625692, lng: 78.942976},


  ]

  const subrd11 = new maps.Polygon({
    paths: subrd11c,
    strokeColor: "BLACK",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "BLACK",
    fillOpacity: 0.8,
  });

  subrd11.setMap(map);

    const main630ft = new maps.Marker({
      position: { lat: 17.626016, lng:78.942976},
      label: {text: "30ft", color: "red", fontSize:"8px"},
      icon: {
        path: maps.SymbolPath.CIRCLE,
        scale: 10,
        strokeWeight: 1,
        fillColor: 'WHITE',
        strokeColor: 'WHITE',
        fillOpacity: 1
      },
      map: map,
      title: "30ft wide road",
      optimized: false,
    });

    main630ft.addListener("click", () => {

        infoWindow.close();
        infoWindow.setContent(main630ft.getTitle());
        infoWindow.open(main630ft.getMap(), main630ft);
    });

  const park1c = [
    {lat: 17.625927, lng: 78.941871},
    {lat: 17.625770, lng:78.941849},
    {lat: 17.625746, lng: 78.942162},
    {lat: 17.625903, lng:78.942186},

    ]


  const park1 = new maps.Polygon({
    paths: park1c,
    strokeColor: "GREEN",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "BLUE",
    fillOpacity: 0.5,
  });

  park1.setMap(map);

  const markPark1 = new maps.Marker({
    position: { lat: 17.625836, lng:78.941999 },
    label: {text: "SocialInfra", color: "white", fontSize: "8px"},
    icon: {
      path: maps.SymbolPath.CIRCLE,
      scale: 8,
      strokeWeight: 0,
      fillColor: 'RED',
      strokeColor: 'RED',
      fillOpacity: 0
    },
    map: map,
      title: "SocialInfra"
  });



  const park2c = [


    {lat: 17.625662, lng: 78.943350},
    {lat:  17.626095, lng: 78.943407},
    {lat: 17.626019, lng:78.943981},
    {lat: 17.625899, lng:78.944210},
    {lat: 17.625564, lng:78.944024},
    {lat: 17.625612, lng:78.943904},

    ]


    const park2 = new maps.Polygon({
      paths: park2c,
      strokeColor: "GREEN",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "GREEN",
      fillOpacity: 0.8,
    });

    park2.setMap(map);

    const markPark2 = new maps.Marker({
      position: { lat: 17.625860, lng:78.943708},
      label: {text: "Park Area", color: "white", fontSize: "8px"},
      icon: {
        path: maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 0,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 0
      },
      map: map,
        title: "Park Area"
    });


  const park3c = [
  {lat:  17.626559, lng:78.945490},
  {lat: 17.626757, lng: 78.945603},
  {lat: 17.626675, lng: 78.945226},


  ]


  const park3 = new maps.Polygon({
    paths: park3c,
    strokeColor: "GREEN",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "BLUE",
    fillOpacity: 0.5,
  });

  park3.setMap(map);

  const markPark3 = new maps.Marker({
    position: { lat: 17.626674, lng:78.945408 },

    label: {text: "SocialInfra", color: "white", fontSize: "8px"},
    icon: {
      path: maps.SymbolPath.CIRCLE,
      scale: 8,
      strokeWeight: 0,
      fillColor: 'RED',
      strokeColor: 'RED',
      fillOpacity: 0
    },
    map: map,
      title: "Open Space"
  });

  const park4c = [

  {lat: 17.624900, lng:78.944950},
  {lat: 17.624902, lng: 78.944879},
  {lat: 17.625191, lng: 78.944845},
  {lat: 17.625214, lng: 78.944802},
  {lat: 17.625252, lng: 78.944825},
  {lat: 17.625120, lng: 78.945076},


  ]


  const park4 = new maps.Polygon({
    paths: park4c,
    strokeColor: "GREEN",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "GREEN",
    fillOpacity: 0.8,
  });

  park4.setMap(map);

  const markPark4 = new maps.Marker({
    position: { lat: 17.625092, lng:78.944938 },
    label: {text: "Park Area", color: "white", fontSize: "8px"},
    icon: {
      path: maps.SymbolPath.CIRCLE,
      scale: 8,
      strokeWeight: 0,
      fillColor: 'RED',
      strokeColor: 'RED',
      fillOpacity: 0
    },
    map: map,
      title: "Open Space"
  });


  const park5c = [
    {lat:  17.624570, lng:78.945337},
    {lat: 17.625121, lng: 78.945653},
    {lat: 17.625327, lng: 78.945289},
    {lat: 17.625177, lng: 78.945197},
    {lat: 17.625045, lng: 78.945446},
    {lat: 17.624810, lng: 78.945319},



  ]


  const park5 = new maps.Polygon({
    paths: park5c,
    strokeColor: "GREEN",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "GREEN",
    fillOpacity: 0.8,
  });

  park5.setMap(map);

  const markPark5 = new maps.Marker({
    position: { lat: 17.625174, lng:78.945365 },
    label: {text: "Park area", color: "white", fontSize: "8px"},
    icon: {
      path: maps.SymbolPath.CIRCLE,
      scale: 8,
      strokeWeight: 0,
      fillColor: 'RED',
      strokeColor: 'RED',
      fillOpacity: 0
    },
    map: map,
      title: "Open Space"
  });


  const park6c = [
    {lat: 17.625177, lng: 78.945197},
    {lat: 17.625045, lng: 78.945446},
    {lat: 17.624810, lng: 78.945319},
    {lat: 17.624856, lng: 78.945195},

    {lat: 17.624878, lng: 78.945025},




  ]

  const park6 = new maps.Polygon({
    paths: park6c,
    strokeColor: "RED",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "BROWN",
    fillOpacity: 0.8,
  });

  park6.setMap(map);

  const markPark6 = new maps.Marker({
    position: { lat: 17.624975, lng:78.945230 },
    label: {text: "Utility", color: "white", fontSize: "8px"},
    icon: {
      path: maps.SymbolPath.CIRCLE,
      scale: 8,
      strokeWeight: 0,
      fillColor: 'RED',
      strokeColor: 'RED',
      fillOpacity: 0
    },
    map: map,
      title: "Open Space"
  });


    const park7c = [
      {lat:  17.624458, lng:78.945387},

      {lat: 17.624297, lng: 78.945612},
      {lat: 17.624445, lng: 78.945683},
    {lat: 17.624561, lng: 78.945435},


    ]

    const park7 = new maps.Polygon({
      paths: park7c,
      strokeColor: "GREEN",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "BLUE",
      fillOpacity: 0.5,
    });

    park7.setMap(map);

    const markPark7 = new maps.Marker({
      position: { lat: 17.624467, lng:78.945506 },
      label: {text: "SocialInfra", color: "white", fontSize: "8px"},
      icon: {
        path: maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 0,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 0
      },
      map: map,
        title: "Open Space"
    });


  const park8c = [
    {lat:  17.249077, lng:78.861629},
    {lat: 17.248801, lng: 78.860782},
    {lat: 17.248857, lng: 78.860723},
    {lat: 17.249600, lng: 78.860793},
    {lat: 17.249569, lng: 78.861635},

  ]

  const park8 = new maps.Polygon({
    paths: park8c,
    strokeColor: "GREEN",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "GREEN",
    fillOpacity: 0.9,
  });

  park8.setMap(map);

  const markPark8 = new maps.Marker({
    position: { lat: 17.247767, lng:78.862318 },

    label: {text: "Park Area", color: "white", fontSize: "8px"},
    icon: {
      path: maps.SymbolPath.CIRCLE,
      scale: 8,
      strokeWeight: 0,
      fillColor: 'RED',
      strokeColor: 'RED',
      fillOpacity: 0
    },
    map: map,
      title: "Open Space"
  });



};

  //to here



  return (



    <div style={{ height: "100vh", width: "100%",  position: "inherit", overflow:"hidden", margin: "0 auto" }}>
       <GoogleMapReact

        bootstrapURLKeys={{
          libraries: ["places", "geometry"],
          key: "AIzaSyB-IhLNuNEMTKd8VHG8Xyij2srPNVS_PR4",
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={({ map, maps }) => renderPolygon(map, maps)}
        yesIWantToUseGoogleMapApiInternals
        options={{
          styles: [ { featureType: "all", elementType: "labels", stylers: [ { visibility:  "on", } ] } ],
          mapTypeControl: false,
          mapTypeId: "hybrid",
          gestureHandling: "greedy",
          mapTypeControl: false,

          streetViewControl: false,

          zoomControl: false,

          fullscreenControl: true,

          rotateControl: true,

          disableDefaultUI: false,

          panControl: true,

          scaleControl: true,

          streetViewControl: true,

          overviewMapControl: true,
        }}
        ref={mapRef}
      >
      </GoogleMapReact>
    </div>

);

}

export default Maptwo;
